import styles from './Playing.module.css';
import React, { CSSProperties, Fragment, useEffect, useMemo, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import {
  canPlaceAnyPiece,
  getAllAvailableCornersForPlayer,
  getAllCellsForPlayer,
  getCellEdgeTouchInfo,
  getCurrentCellsToHoverOver,
  getShapeTouchingEdgesOfOtherShapes,
} from '@magicyard/magicblocks-game/src/utils/moves.util';
import { EMPTY_CELL, getGridSize, getWinner, Phases, playerIdToCellValue } from '@magicyard/magicblocks-game/src/Game';
import {
  BoardPropTypes,
  Cell,
  hexToRgba,
  IPlayer,
  SHAPE_NAME_TO_SIZE,
  SHAPE_NAMES,
  valueToBrightness,
  valueToColor,
  valueToHighlightColor,
  valueToSecondaryColor,
  valueToXColor,
} from '@magicyard/magicblocks-game/src/Types';
import { Pieces } from '@magicyard/magicblocks-shared/magicblocks/Pieces';
import { track } from '@magicyard/shared/src/localAnalytics';
import { Textfit } from 'react-textfit';
import { useAudio, usePlayHighlight } from './AudioManager';

function getRandomNumber(min: number, max: number): number {
  const randomFloat: number = Math.random();
  return randomFloat * (max - min) + min;
}

const getCellSize = (players: IPlayer[]) => (players.length > 2 ? 45 : 65);

export const Playing = () => {
  const { G } = useGameObject();
  const cellSize = useMemo(() => getCellSize(G.players), [G.players.length]);
  const hoverCells = getCurrentCellsToHoverOver(G);
  const canPlace = canPlaceAnyPiece(G, G.activePlayer, hoverCells);

  useAudio();
  useEffect(() => {
    track('Playing phase loaded');
  }, []);

  useEffect(() => {
    if (G.activePlayer === '0' && G.lastPlacedPiece?.id !== null && G.lastPlacedPiece?.id !== '0') {
      track('Round ended', { round: SHAPE_NAMES.length - G.piecesForPlayer[G.activePlayer].length });
    }
  }, [G.activePlayer, G.lastPlacedPiece?.id]);

  const allCorners = useMemo(
    () => getAllAvailableCornersForPlayer(G.players, G.gameGrid, playerIdToCellValue(G.activePlayer)),
    [G.lastPlacedPiece?.id, G.activePlayer]
  );

  return (
    <>
      <Announcement />
      <div className={styles.title} />
      <AllPieces />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginTop: 80,
        }}
      >
        <Tutorial allCorners={allCorners} cellSize={cellSize} canPlace={canPlace} />
        <PointParticles cellSize={cellSize} />
        <Xs canPlace={canPlace} hoverCells={hoverCells} cellSize={cellSize} allCorners={allCorners} />
        <HoveringCells cellSize={cellSize} hoverCells={hoverCells} canPlace={canPlace} />
        <HighlightCorners cellSize={cellSize} corners={allCorners} />
        <ActiveCells cellSize={cellSize} />
        <NeighbourHighlight cellSize={cellSize} />
        <GameGrid cellSize={cellSize} />
      </div>
    </>
  );
};

const Announcement = () => {
  const [announcement, setAnnouncement] = useState<{
    text: string;
    disappearing: boolean;
    bg: string;
  } | null>(null);
  const { ctx, G } = useGameObject();
  useEffect(() => {
    if (ctx.phase === Phases.GameEnd) {
      const winner = getWinner(G);
      track('Game end phase loaded', {
        winner: winner.playerId,
        isBot: G.players[+winner.playerId].isBot,
      });

      setAnnouncement({
        text: `${G.players[+winner.playerId].name} won with ${winner.score} points!\n Try playing again!`,
        disappearing: false,
        bg: hexToRgba(valueToColor[winner.playerId], 0.8),
      });
    } else if (G.playersThatSkipped.length > 0) {
      const player = G.players[+G.playersThatSkipped[+G.playersThatSkipped.length - 1]];
      setAnnouncement({
        text: `${player.name} has no more moves!\n they are out of the game!`,
        disappearing: true,
        bg: hexToRgba(valueToColor[player.id], 0.8),
      });
      track('Player out of game shown', { player: player.id });
    }
  }, [G.playersThatSkipped.length]);
  if (announcement === null) {
    return <></>;
  }
  return (
    <div
      className={`${styles.announcement} + ${
        announcement.disappearing ? styles.announcemnetDisappear : styles.announcemnetForever
      }`}
      style={{
        background: announcement.bg,
      }}
      key={announcement.text}
      onAnimationEnd={() => {
        if (announcement?.disappearing) {
          setAnnouncement(null);
        }
      }}
    >
      {announcement.text}
    </div>
  );
};

const AllPieces = () => {
  const { G } = useGameObject();
  return (
    <>
      {G.players.map((p, i) => (
        <div
          key={p.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'absolute',
            width: 300,
            top: i === 0 || i === 1 ? 150 : undefined,
            bottom: i === 2 || i === 3 ? 150 : undefined,
            left: i === 0 || i === 3 ? 80 : undefined,
            right: i === 1 || i === 2 ? 80 : undefined,
          }}
        >
          <Textfit mode={'single'} style={{ width: 300, color: '#636363' }} max={30}>
            {G.players[+p.id].name}
          </Textfit>
          <div style={{ color: 'black' }}>Score: {getAllCellsForPlayer(G.gameGrid, +p.id).length}</div>
          {G.activePlayer === p.id && <div className={styles.piecesBackground} />}
          <Pieces
            activePlayerId={G.activePlayer}
            onSelectPiece={() => undefined}
            playerId={p.id}
            hoveringPiece={G.currentlyHoveringPiece}
            piecesForPlayer={G.piecesForPlayer}
            size={'sm'}
          />
        </div>
      ))}
    </>
  );
};

const Xs = ({
  cellSize,
  canPlace,
  hoverCells,
  allCorners,
}: {
  cellSize: number;
  canPlace: boolean;
  hoverCells: Cell[];
  allCorners: Cell[];
}) => {
  const { G } = useGameObject();
  const currentTouchingEdges = canPlace
    ? []
    : getShapeTouchingEdgesOfOtherShapes(
        getGridSize(G.players),
        G.gameGrid,
        hoverCells,
        playerIdToCellValue(G.activePlayer),
        allCorners
      );

  return (
    <>
      {currentTouchingEdges.map((cellWithInfo, i) => {
        const offset = getOffsetForX(cellSize, cellWithInfo);
        const left = cellWithInfo.cell.x * cellSize + offset.left;
        const top = cellWithInfo.cell.y * cellSize + offset.top;
        return (
          <div
            key={cellWithInfo.cell.x + ' ' + cellWithInfo.cell.y}
            className={styles.xContainer}
            style={{
              width: cellSize,
              height: cellSize,
              animationDelay: i * 0.2 + 0.3 + 's',
              left: left,
              top: top,
            }}
          >
            <div
              className={styles.x0}
              style={{
                backgroundColor: valueToXColor[G.activePlayer],
                borderRadius: cellSize * 0.035,
                width: cellSize * 0.5,
                height: cellSize * 0.07,
              }}
            />
            <div
              className={styles.x1}
              style={{
                backgroundColor: valueToXColor[G.activePlayer],
                width: cellSize * 0.5,
                height: cellSize * 0.07,
                borderRadius: cellSize * 0.035,
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const HoveringCells = ({
  hoverCells,
  cellSize,
  canPlace,
}: {
  hoverCells: Cell[];
  cellSize: number;
  canPlace: boolean;
}) => {
  const { G } = useGameObject();
  return (
    <>
      {hoverCells.map((cell, i) => {
        const left = cell.x * cellSize + cellSize * 0.1;
        const top = cell.y * cellSize + cellSize * 0.1;
        return (
          <div
            key={i}
            className={styles.hoverCell}
            style={{
              filter: canPlace ? 'brightness(1.5)' : undefined,
              width: cellSize * 0.8 + 'px',
              height: cellSize * 0.8 + 'px',
              animationDelay: i * 0.1 + 's',
              left: left,
              top: top,
              background: valueToSecondaryColor[playerIdToCellValue(G.activePlayer)],
            }}
          />
        );
      })}
    </>
  );
};

const HighlightCorners = ({ cellSize, corners }: { cellSize: number; corners: Cell[] }) => {
  const { G } = useGameObject();
  const initialDelay = 1;
  const delayPerHighlight = 0.1;
  usePlayHighlight(corners, G.activePlayer, initialDelay, delayPerHighlight);

  return (
    <>
      {corners
        .sort((a, b) => (a.x - b.x === 0 ? a.y - b.y : a.x - b.x))
        .map((cell, i) => {
          const left = cell.x * cellSize + cellSize * 0.1;
          const top = cell.y * cellSize + cellSize * 0.1;

          const delay = i * delayPerHighlight + initialDelay;
          return (
            <div
              key={cell.x + ' ' + cell.y + ' ' + i}
              className={styles.highlightCell}
              style={{
                color: hexToRgba(valueToHighlightColor[playerIdToCellValue(G.activePlayer)], 0.5),
                // ...getBorderRadiusForHighlight(G, cell),
                width: cellSize * 0.8,
                height: cellSize * 0.8,
                animationDelay: `${delay}s, ${delay}s`,
                top: top,
                left: left,
                filter: `brightness(${valueToBrightness[playerIdToCellValue(G.activePlayer)]})`,
                background: valueToHighlightColor[playerIdToCellValue(G.activePlayer)],
              }}
            />
          );
        })}
    </>
  );
};

const ActiveCells = ({ cellSize }: { cellSize: number }) => {
  const { G } = useGameObject();
  const activeCells = useMemo(() => {
    const cells: Cell[] = [];
    G.gameGrid.forEach((col, x) => {
      col.forEach((row, y) => {
        if (row !== EMPTY_CELL) {
          cells.push({ x, y });
        }
      });
    });
    return cells;
  }, [G.lastPlacedPiece?.id]);
  return (
    <>
      {activeCells.map((c) => (
        <div
          key={c.x + ' ' + c.y}
          className={styles.selectedCellBody}
          style={{
            top: c.y * cellSize,
            left: c.x * cellSize,
            background: valueToColor['' + G.gameGrid[c.x][c.y]],
            height: cellSize,
            width: cellSize,
          }}
        />
      ))}
    </>
  );
};

const PointParticles = ({ cellSize }: { cellSize: number }) => {
  const { G } = useGameObject();
  const particles = G.lastPlacedPiece?.cells;
  const lastPlacedById = G.lastPlacedPiece?.placedBy;

  const [target, setTarget] = useState(particles ?? []);

  useEffect(() => {
    setTarget(G.lastPlacedPiece?.cells.map((c) => ({ x: c.x * cellSize, y: c.y * cellSize })) ?? []);
    const id = window.setTimeout(() => {
      setTarget((old) => {
        return old.map((c) => ({
          x: c.x + getRandomNumber(-5, 5) * cellSize,
          y: c.y + getRandomNumber(-5, 5) * cellSize,
        }));
      });
    }, 100);
    const id2 = window.setTimeout(() => {
      setTarget((old) => {
        return old.map((c) => ({
          x: playerIdToTutorialScoreCountPositionAdjustment[lastPlacedById ?? 0].left,
          y: playerIdToTutorialScoreCountPositionAdjustment[lastPlacedById ?? 0].top,
        }));
      });
    }, 800);
    const id3 = window.setTimeout(() => {
      setTarget([]);
    }, 1600);
    return () => {
      window.clearTimeout(id);
      window.clearTimeout(id2);
      window.clearTimeout(id3);
    };
  }, [lastPlacedById]);

  if (particles === undefined || lastPlacedById === undefined || target.length === 0) {
    return <></>;
  }

  return (
    <>
      {particles.map((c, i) => (
        <div
          key={i + ' ' + lastPlacedById}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 10000,
            transition: `${getRandomNumber(0.3, 0.7)}s`,
            width: cellSize * 0.8,
            height: cellSize * 0.8,
            position: 'absolute',
            left: target[i].x,
            top: target[i].y,
            background: valueToHighlightColor[lastPlacedById],
          }}
        />
      ))}
    </>
  );
};
const NeighbourHighlight = ({ cellSize }: { cellSize: number }) => {
  const { G } = useGameObject();
  const allNeighbours = useMemo(
    () => getNeighborsAroundCells(G.lastPlacedPiece?.cells ?? [], 2, 0, getGridSize(G.players) - 1),
    [G.lastPlacedPiece?.id]
  );

  return (
    <>
      {allNeighbours.map((cr) => (
        <div
          className={`${styles.animationCell} ${
            cr === undefined
              ? ''
              : cr.distance > 2
              ? styles.animationCellAnimate
              : styles['animationCellAnimate-' + cr.distance]
          }`}
          style={{
            animationDelay: Math.abs(cr?.distance ?? 0) * 100 + 'ms',
            height: cellSize * 0.6,
            width: cellSize * 0.6,
            left: cr.cell.x * cellSize + cellSize * 0.2,
            top: cr.cell.y * cellSize + cellSize * 0.2,
            backgroundColor: hexToRgba(valueToColor[G.lastPlacedPiece?.placedBy ?? 0], 0.3),
          }}
          key={cr.cell.x + ' ' + cr.cell.y + ' ' + cr.distance + ' ' + G.lastPlacedPiece?.id}
        />
      ))}
    </>
  );
};

export const GameGrid = ({ cellSize }: { cellSize: number }) => {
  const { G } = useGameObject();
  return (
    <div
      className={styles.gameGridRoot}
      style={{
        background: valueToHighlightColor[-1],
        width: cellSize * G.gameGrid.length,
        height: cellSize * G.gameGrid.length,
      }}
    >
      {G.gameGrid.map((col, x) => (
        <Fragment key={'' + x}>
          <div
            className={styles.col}
            style={{
              left: x * cellSize,
              height: cellSize * col.length,
            }}
          />
          <div
            className={styles.row}
            style={{
              top: x * cellSize,
              width: cellSize * col.length,
            }}
          />
        </Fragment>
      ))}
    </div>
  );
};

const getOffsetForX = (
  cellSize: number,
  cellWithInfo: {
    cell: Cell;
    info: ReturnType<typeof getCellEdgeTouchInfo>;
  }
): {
  top: number;
  left: number;
} => {
  const offset = cellSize * 0.5;
  if (cellWithInfo.info.isTouchingFromAbove) {
    return { top: -offset, left: 0 };
  }
  if (cellWithInfo.info.isTouchingFromBelow) {
    return { top: offset, left: 0 };
  }
  if (cellWithInfo.info.isTouchingFromRight) {
    return { top: 0, left: offset };
  }
  if (cellWithInfo.info.isTouchingFromLeft) {
    return { top: 0, left: -offset };
  }
  return { top: 0, left: 0 };
};

function getNeighborsAroundCells(
  cells: Cell[],
  N: number,
  min: number,
  max: number
): {
  cell: Cell;
  distance: number;
}[] {
  const distanceFromNeighbour: number[][] = [];
  const neighborsSet = new Set<string>();

  for (const cell of cells) {
    for (let i = Math.max(min, cell.x - N); i <= Math.min(cell.x + N, max); i++) {
      for (let j = Math.max(min, cell.y - N); j <= Math.min(cell.y + N, max); j++) {
        const neighbor: Cell = { x: i, y: j };
        if (!cells.some((c) => c.x === neighbor.x && c.y === neighbor.y)) {
          const dx = Math.abs(cell.x - i);
          const dy = Math.abs(cell.y - j);
          neighborsSet.add(`${neighbor.x},${neighbor.y}`);
          if (distanceFromNeighbour[i] === undefined) {
            distanceFromNeighbour[i] = [];
          }

          distanceFromNeighbour[i][j] = Math.min(distanceFromNeighbour[i][j] ?? Number.MAX_VALUE, dx + dy);
        }
      }
    }
  }

  const neighbors: {
    cell: Cell;
    distance: number;
  }[] = [...neighborsSet].map((entry) => {
    const [x, y] = entry.split(',').map(Number);

    return { cell: { x, y }, distance: distanceFromNeighbour[x][y] };
  });

  return neighbors;
}

const getStep = (G: BoardPropTypes, canPlace: boolean) => {
  if (G.piecesForPlayer[G.activePlayer].length === SHAPE_NAMES.length) {
    if (G.currentlyHoveringPiece === null) {
      return 0;
    }
    if (!canPlace) {
      return 1;
    }
    return 2;
  }

  if (G.piecesForPlayer[G.activePlayer].length === SHAPE_NAMES.length - 1) {
    if (G.currentlyHoveringPiece === null) {
      return 0;
    }
    if (!canPlace) {
      return 3;
    }
    return 4;
  }
  if (G.piecesForPlayer[G.activePlayer].length === SHAPE_NAMES.length - 2) {
    if (G.currentlyHoveringPiece === null) {
      return 0;
    }
    if (!canPlace) {
      return 5;
    }
    return 6;
  }

  if (G.piecesForPlayer[G.activePlayer].length === SHAPE_NAMES.length - 3) {
    if (G.currentlyHoveringPiece === null) {
      return 0;
    }
    if (!canPlace) {
      return 7;
    }
    return 8;
  }

  return null;
};
const Tutorial = ({ allCorners, cellSize, canPlace }: { allCorners: Cell[]; cellSize: number; canPlace: boolean }) => {
  const { G } = useGameObject();

  const tutorialStep = getStep(G, canPlace);

  const skipShowingTutorial = G.players[+G.activePlayer].isBot;

  useEffect(() => {
    if (!skipShowingTutorial && tutorialStep !== null) {
      track(`Tutorial step ${tutorialStep} shown`);
    }
  }, [tutorialStep, skipShowingTutorial]);

  if (skipShowingTutorial) {
    return <></>;
  }

  const dataForStep: Record<number, { text: string; style: CSSProperties }> = {
    0: {
      text: 'Select a piece on your phone',
      style: {
        top: G.players.length > 2 ? cellSize * 6 : cellSize * 1,
        left: cellSize,
        height: cellSize * 2,
        width: (getGridSize(G.players) - 2) * cellSize,
      },
    },
    1: {
      text: 'Move it on the highlighted spot.',
      style: {
        top: allCorners[0].y * cellSize + playerIdToTutorialCornerPositionAdjustment[G.activePlayer].top * cellSize,
        left: allCorners[0].x * cellSize + playerIdToTutorialCornerPositionAdjustment[G.activePlayer].left * cellSize,
        width: cellSize * 10,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        textAlign: 'center',
      },
    },
    2: {
      text: 'Tap "Place!" on your phone.',
      style: {
        top: G.players.length > 2 ? cellSize * 6 : cellSize * 1,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
    3: {
      text: "Can't touch the edges of your own pieces",
      style: {
        top: G.players.length > 2 ? cellSize * 6 : cellSize * 1,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
    4: {
      text: 'Tap "Place!" on your phone.',
      style: {
        textAlign: 'center',
        top: G.players.length > 2 ? (getGridSize(G.players) - 9) * cellSize : (getGridSize(G.players) - 3) * cellSize,
        left: G.players.length > 2 ? cellSize * 2 : cellSize,
        width: getGridSize(G.players) * (cellSize - 9),
        height: G.players.length > 2 ? 3 * cellSize : cellSize * 2,
      },
    },
    5: {
      text: `Gain more points by placing bigger pieces`,
      style: {
        top: G.players.length > 2 ? cellSize * 6 : cellSize * 1,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
    6: {
      text: `This piece will earn you ${SHAPE_NAME_TO_SIZE[G.currentlyHoveringPiece?.shape ?? '.']} points`,
      style: {
        top: G.players.length > 2 ? (getGridSize(G.players) - 9) * cellSize : (getGridSize(G.players) - 3) * cellSize,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
    7: {
      text: 'The game ends when no one can place any pieces',
      style: {
        top: G.players.length > 2 ? cellSize * 6 : cellSize * 1,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
    8: {
      text: 'Win by getting the most points!',
      style: {
        top: G.players.length > 2 ? (getGridSize(G.players) - 9) * cellSize : (getGridSize(G.players) - 3) * cellSize,
        left: cellSize * 2,
        height: G.players.length > 2 ? cellSize * 3 : cellSize * 2,
        width: G.players.length > 2 ? cellSize * 16 : cellSize * 10,
        textAlign: 'center',
      },
    },
  };

  if (tutorialStep === null) {
    return <></>;
  }
  return (
    <div
      className={styles.tutorialTitle}
      style={{
        transition: '0.8s',
        background: hexToRgba(valueToHighlightColor[G.activePlayer], 0.5),
        ...dataForStep[tutorialStep].style,
      }}
    >
      {dataForStep[tutorialStep].text}
    </div>
  );
};

const playerIdToTutorialCornerPositionAdjustment: Record<string, { left: number; top: number }> = {
  '0': { top: 1, left: 1 },
  '1': { top: 1, left: -10 },
  '2': { top: -3, left: -10 },
  '3': { top: -3, left: 1 },
};

const playerIdToTutorialScoreCountPositionAdjustment: Record<string, { left: number; top: number }> = {
  '0': {
    top: 99,
    left: -450,
  },
  '1': { top: 99, left: 1025 },
  '2': { top: 500, left: 1025 },
  '3': { top: 500, left: -450 },
};
