import React, { CSSProperties, useEffect } from 'react';
import styles from './Tutorial.module.css';
import { valueToColor, valueToHighlightColor } from '@magicyard/magicblocks-game/src/Types';
import { track } from '@magicyard/shared/src/localAnalytics';
import { GameGrid } from './Playing';

const Yellow = () => (
  <span
    style={{
      background: valueToHighlightColor[0],
      borderRadius: '15px',
      flexShrink: 0,
      height: 40,
      width: 120,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    Yellow
  </span>
);

const Span = ({ children, style }: { children: React.ReactNode; style?: CSSProperties }) => (
  <span style={{ flexShrink: 0, ...style }}>{children}</span>
);

const Highlight = ({ children }: { children: React.ReactNode; style?: CSSProperties }) => (
  <span
    style={{
      borderRadius: '15px',
      flexShrink: 0,
      border: '1px solid black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {children}
  </span>
);

export const Tutorial = () => {
  useEffect(() => {
    track('Tutorial Presented');
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.cta} style={{ animationDelay: cssAnimationDuration * 0.5 + 's' }}>
        <div style={{ marginBottom: 70, marginLeft: 24 }}>Choose a game mode on your phone!</div>
      </div>
    </div>
  );
};

const cssAnimationDuration = 1;
const BodyPart = ({
  delay,
  children,
  imageClass,
  iconClass,
}: {
  delay: number;
  children: React.ReactNode;
  imageClass: string;
  iconClass: string;
}) => {
  return (
    <div className={styles.tileContainer}>
      <div
        className={`${imageClass} ${styles.imageBorder}`}
        style={{ animationDelay: delay + cssAnimationDuration + 's' }}
      />
      <div
        className={`${styles.icon} ${iconClass}`}
        style={{ animationDelay: delay + cssAnimationDuration * 2 + 's' }}
      />
      <div className={styles.bodyPartChildContainer} style={{ animationDelay: delay + cssAnimationDuration * 3 + 's' }}>
        {children}
      </div>
    </div>
  );
};
