import { Howl, Howler } from 'howler';
import { useCallback, useEffect, useRef } from 'react';
import MoveDown from '../../assets/audio/MoveDown.mp3';
import MoveLeft from '../../assets/audio/MoveLeft.mp3';
import MoveRight from '../../assets/audio/MoveRight.mp3';
import MoveUp from '../../assets/audio/MoveUp.mp3';
import BlockFlip from '../../assets/audio/BlockFlip.mp3';
import BlockRotate from '../../assets/audio/BlockRotate.mp3';
import player1BlockAppear from '../../assets/audio/P1BlockAppear.mp3';
import player2BlockAppear from '../../assets/audio/P2BlockAppear.mp3';
import player3BlockAppear from '../../assets/audio/P3BlockAppear.mp3';
import player4BlockAppear from '../../assets/audio/P4BlockAppear.mp3';
import player1BlockPlace from '../../assets/audio/P1BlockPlace.mp3';
import player2BlockPlace from '../../assets/audio/P2BlockPlace.mp3';
import player3BlockPlace from '../../assets/audio/P3BlockPlace.mp3';
import player4BlockPlace from '../../assets/audio/P4BlockPlace.mp3';
import P1DotAppear2 from '../../assets/audio/P1DotAppear2.mp3';
import P1DotAppear1 from '../../assets/audio/P1DotAppear1.mp3';
import P1DotAppear3 from '../../assets/audio/P1DotAppear3.mp3';
import P1DotAppear4 from '../../assets/audio/P1DotAppear4.mp3';
import P1DotAppear5 from '../../assets/audio/P1DotAppear5.mp3';
import P1DotAppear6 from '../../assets/audio/P1DotAppear6.mp3';

import P2DotAppear6 from '../../assets/audio/P2DotAppear6.mp3';
import P2DotAppear5 from '../../assets/audio/P2DotAppear5.mp3';
import P2DotAppear4 from '../../assets/audio/P2DotAppear4.mp3';
import P2DotAppear3 from '../../assets/audio/P2DotAppear3.mp3';
import P2DotAppear2 from '../../assets/audio/P2DotAppear2.mp3';
import P2DotAppear1 from '../../assets/audio/P2DotAppear1.mp3';

import P3DotAppear6 from '../../assets/audio/P3DotAppear6.mp3';
import P3DotAppear5 from '../../assets/audio/P3DotAppear5.mp3';
import P3DotAppear4 from '../../assets/audio/P3DotAppear4.mp3';
import P3DotAppear3 from '../../assets/audio/P3DotAppear3.mp3';
import P3DotAppear2 from '../../assets/audio/P3DotAppear2.mp3';
import P3DotAppear1 from '../../assets/audio/P3DotAppear1.mp3';

import P4DotAppear6 from '../../assets/audio/P4DotAppear6.mp3';
import P4DotAppear5 from '../../assets/audio/P4DotAppear5.mp3';
import P4DotAppear4 from '../../assets/audio/P4DotAppear4.mp3';
import P4DotAppear3 from '../../assets/audio/P4DotAppear3.mp3';
import P4DotAppear2 from '../../assets/audio/P4DotAppear2.mp3';
import P4DotAppear1 from '../../assets/audio/P4DotAppear1.mp3';

import backgroundMusic from '../../assets/audio/MagicBlocksMusic.mp3';

import { useEffectListener } from 'bgio-effects/react';
import { Cell, Effects } from '@magicyard/magicblocks-game/src/Types';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { PlayerID } from 'boardgame.io';

const searchParams = new URLSearchParams(window.location.search);
const html5Fallback = searchParams.get('version_name')?.toLowerCase().includes('android') === true;

if (html5Fallback) {
  Howler.usingWebAudio = false;
}

interface AudioPlayerCompatible {
  play: () => void;
  pause: () => void;
  stop: () => void;
  mute: (onOff: boolean) => void;
  loop: (onOff: boolean) => void;
}

const useAudioPlayerWithFallback = (html5Fallback: boolean, src: string): AudioPlayerCompatible => {
  const didInit = useRef<AudioPlayerCompatible | null>(null);

  if (didInit.current) {
    return didInit.current;
  }

  if (html5Fallback) {
    const audio = new Audio();
    audio.src = src;
    didInit.current = {
      play: () => audio.play(),
      pause: () => audio.pause(),
      stop: () => {
        audio.pause();
        audio.currentTime = 0;
      },
      mute: (onOff) => (onOff ? (audio.volume = 0) : (audio.volume = 1)),
      loop: () => (audio.loop = true),
    };
    return didInit.current;
  } else {
    const howl = new Howl({ src: [src], html5: html5Fallback });
    didInit.current = {
      play: () => howl.play(),
      pause: () => howl.pause(),
      stop: () => howl.stop(),
      mute: (onOff) => howl.mute(onOff),
      loop: () => howl.loop(true),
    };
    return didInit.current;
  }
};

export const useBgMusic = () => useAudioPlayerWithFallback(html5Fallback, backgroundMusic);

export const useAudio = () => {
  const moveUp = useAudioPlayerWithFallback(html5Fallback, MoveUp);
  const moveDown = useAudioPlayerWithFallback(html5Fallback, MoveDown);
  const moveLeft = useAudioPlayerWithFallback(html5Fallback, MoveLeft);
  const moveRight = useAudioPlayerWithFallback(html5Fallback, MoveRight);
  const blockFlip = useAudioPlayerWithFallback(html5Fallback, BlockFlip);
  const blockRotate = useAudioPlayerWithFallback(html5Fallback, BlockRotate);
  const playersSelectPiece = [
    useAudioPlayerWithFallback(html5Fallback, player1BlockAppear),
    useAudioPlayerWithFallback(html5Fallback, player2BlockAppear),
    useAudioPlayerWithFallback(html5Fallback, player3BlockAppear),
    useAudioPlayerWithFallback(html5Fallback, player4BlockAppear),
  ];
  const playersPlacePiece = [
    useAudioPlayerWithFallback(html5Fallback, player1BlockPlace),
    useAudioPlayerWithFallback(html5Fallback, player2BlockPlace),
    useAudioPlayerWithFallback(html5Fallback, player3BlockPlace),
    useAudioPlayerWithFallback(html5Fallback, player4BlockPlace),
  ];

  const focus = useCallback(() => {
    moveUp.mute(false);
    moveDown.mute(false);
    moveLeft.mute(false);
    moveRight.mute(false);
    blockFlip.mute(false);
    blockRotate.mute(false);
    playersSelectPiece.forEach((m) => m.mute(false));
    playersPlacePiece.forEach((m) => m.mute(false));
  }, []);
  const blur = useCallback(() => {
    moveUp.mute(true);
    moveDown.mute(true);
    moveLeft.mute(true);
    moveRight.mute(true);
    blockFlip.mute(true);
    blockRotate.mute(true);
    playersSelectPiece.forEach((m) => m.mute(true));
    playersPlacePiece.forEach((m) => m.mute(true));
  }, []);

  useNativeFocus(focus, blur);

  useEffectListener(
    Effects.MovePiece,
    ({ direction }: any) => {
      if (direction.x > 0) {
        moveRight.play();
      } else if (direction.x < 0) {
        moveLeft.play();
      } else if (direction.y > 0) {
        moveUp.play();
      } else if (direction.y < 0) {
        moveDown.play();
      }
    },
    []
  );

  useEffectListener(
    Effects.RotatePiece,
    () => {
      blockRotate.play();
    },
    []
  );

  useEffectListener(
    Effects.MirrorPiece,
    () => {
      blockFlip.play();
    },
    []
  );

  useEffectListener(
    Effects.SelectPiece,
    ({ playerId }: any) => {
      playersSelectPiece[+playerId].play();
    },
    []
  );

  useEffectListener(
    Effects.PlacePiece,
    ({ playerId }: any) => {
      playersPlacePiece[playerId].play();
    },
    []
  );
};

export const usePlayHighlight = (allCorners: Cell[], playerId: PlayerID, initialDelay: number, delayMult: number) => {
  const p1DotAppear2 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear2);
  const p1DotAppear1 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear1);
  const p1DotAppear3 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear3);
  const p1DotAppear4 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear4);
  const p1DotAppear5 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear5);
  const p1DotAppear6 = useAudioPlayerWithFallback(html5Fallback, P1DotAppear6);
  const p2DotAppear6 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear6);
  const p2DotAppear5 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear5);
  const p2DotAppear4 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear4);
  const p2DotAppear3 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear3);
  const p2DotAppear2 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear2);
  const p2DotAppear1 = useAudioPlayerWithFallback(html5Fallback, P2DotAppear1);
  const p3DotAppear6 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear6);
  const p3DotAppear5 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear5);
  const p3DotAppear4 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear4);
  const p3DotAppear3 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear3);
  const p3DotAppear2 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear2);
  const p3DotAppear1 = useAudioPlayerWithFallback(html5Fallback, P3DotAppear1);
  const p4DotAppear6 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear6);
  const p4DotAppear5 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear5);
  const p4DotAppear4 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear4);
  const p4DotAppear3 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear3);
  const p4DotAppear2 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear2);
  const p4DotAppear1 = useAudioPlayerWithFallback(html5Fallback, P4DotAppear1);

  const p1Dots = [p1DotAppear1, p1DotAppear2, p1DotAppear3, p1DotAppear4, p1DotAppear5, p1DotAppear6];
  const p2Dots = [p2DotAppear1, p2DotAppear2, p2DotAppear3, p2DotAppear4, p2DotAppear5, p2DotAppear6];
  const p3Dots = [p3DotAppear1, p3DotAppear2, p3DotAppear3, p3DotAppear4, p3DotAppear5, p3DotAppear6];
  const p4Dots = [p4DotAppear1, p4DotAppear2, p4DotAppear3, p4DotAppear4, p4DotAppear5, p4DotAppear6];

  const playersDots = [p1Dots, p2Dots, p3Dots, p4Dots];
  const focus = useCallback(() => {
    playersDots.forEach((m) => m.forEach((m2) => m2.mute(false)));
  }, []);
  const blur = useCallback(() => {
    playersDots.forEach((m) => m.forEach((m2) => m2.mute(true)));
  }, []);

  useNativeFocus(focus, blur);

  useEffect(() => {
    const ids = allCorners.map((cell, i) =>
      setTimeout(() => {
        if (Math.floor(i / playersDots.length) % 2 === 0) {
          playersDots[+playerId][i % playersDots.length].play();
        } else {
          playersDots[+playerId][playersDots.length - 1 - (i % playersDots.length)].play();
        }
      }, (initialDelay + i * delayMult) * 1000)
    );
    return () => {
      ids.forEach((id) => window.clearTimeout(id));
    };
  }, [delayMult, initialDelay, playerId]);
};
