import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Phases } from '@magicyard/magicblocks-game/src/Game';
import { useGameObject } from '../store/GameContext';

import { Title } from './Title';
import { Display } from '@magicyard/shared/platform/lib/api';
import styles from './GameManager.module.css';
import { Playing } from './Stages/Playing';
import { Tutorial } from './Stages/Tutorial';
import { Background } from '../components/Background/Background';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { useBgMusic } from './Stages/AudioManager';

interface PhaseData {
  mainPage: () => JSX.Element;
  transition: (() => JSX.Element) | null;
  hasQr: boolean;
}

const Sync = () => {
  const { moves } = useGameObject();
  useEffect(() => {
    moves.endSync();
  }, []);
  return (
    <Title
      title={'Starting...'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        color: 'black',
      }}
    />
  );
};

const StageManager = ({ display }: { display: Display | undefined }) => {
  const { ctx, G } = useGameObject();
  const { isTransition } = G;
  const phase = ctx.phase as Phases;
  const phaseToScreen: Record<Phases, PhaseData> = useMemo(
    () => ({
      [Phases.Sync]: {
        mainPage: Sync,
        transition: null,
        hasQr: false,
      },
      [Phases.Tutorial]: {
        mainPage: Tutorial,
        transition: null,
        hasQr: false,
      },
      [Phases.Playing]: {
        mainPage: Playing,
        transition: null,
        hasQr: false,
      },
      [Phases.GameEnd]: {
        mainPage: Playing,
        transition: null,
        hasQr: false,
      },
    }),
    []
  );

  const [shakeState, setShakeState] = useState<{ i: number } | null>(null);

  const bgMusic = useBgMusic();

  const focus = useCallback(() => {
    bgMusic.play();
  }, []);

  const blur = useCallback(() => {
    bgMusic.pause();
  }, []);

  useNativeFocus(focus, blur);

  useEffect(() => {
    if (G.lastPlacedPiece !== null) {
      setShakeState((old) => {
        if (old === null) {
          return { i: 0 };
        }
        return { i: (old.i + 1) % 2 };
      });
    }
  }, [G.lastPlacedPiece?.id]);

  const curr = phaseToScreen[phase ?? Phases.GameEnd];
  return (
    <Background>
      {/*<AudioManager phase={phase} isTransition={isTransition && curr.transition !== null} />*/}
      <div
        className={shakeState !== null ? styles['shake' + shakeState.i] : undefined}
        style={{
          width: '100%',
          height: '100%',
          backgroundSize: 'contain',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {curr.hasQr && display !== undefined ? (
          <div className={styles['game_manager-qr']}>
            <div className={styles['game_manager-qr_body']}>room code: {display.code}</div>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          {isTransition && curr.transition !== null ? (
            <curr.transition />
          ) : (
            <curr.mainPage key={ctx.phase === Phases.Playing || ctx.phase === Phases.GameEnd ? 1 : ctx.phase} />
          )}
        </div>
      </div>
    </Background>
  );
};

export default StageManager;
